import { graphql } from "gatsby"
import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { PageLayout, PageTitle } from "../components"
import CourseLink from "../components/CourseLink"
import { SEO, Utils } from "../utils"

export default ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const allFeaturedImages = data.allFile.edges || []
  const allPosts = data.allMarkdownRemark.edges || []
  const regex = /\/[meetup].*\/|$/
  //const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex)

  // const handleChange = e => {
  //   const query = e.target.value

  //   const filteredData = allPosts.filter(post => {
  //     // query will run on the following fields
  //     const { description, title, tags, author } = post.node.frontmatter
  //     // standardize query
  //     const stdQuery = query.toLowerCase()
  //     return (
  //       post.node.excerpt.toLowerCase().includes(stdQuery) ||
  //       (description && description.toLowerCase().includes(stdQuery)) ||
  //       title.toLowerCase().includes(stdQuery) ||
  //       author.toLowerCase().includes(stdQuery) ||
  //       (tags && tags.join("").toLowerCase().includes(stdQuery))
  //     )
  //   })

  //   setState({
  //     query,
  //     filteredData,
  //   })
  // }

  const { filteredData, query } = state
  const filteredProjets = query !== "" ? filteredData : allPosts

  return (
    <PageLayout pageName="Formations">
      <SEO title="Formations" />
      <PageTitle title="Formations" />
      <Container className="p-2 w-auto text-left d-flex flex-wrap">
        <Row>
          <Col md="8">
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p> */}
          </Col>
        </Row>
        {filteredProjets.map(({ node }, key) => (
          <CourseLink
            key={key}
            to={node.fields.slug}
            title={node.frontmatter.title}
            subtitle={node.frontmatter.date}
            color={node.frontmatter.color}
            image={node.frontmatter.image}
            place={node.frontmatter.place}
            excerpt={node.excerpt}
            soon={node.frontmatter.soon}
            show={node.frontmatter.show}
            description={node.frontmatter.description}
          />
        ))}
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/formations/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            color
            image
            place
            tags
            author
            date(formatString: "DD MMMM, YYYY")
            soon
            show
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allFile(
      filter: {
        extension: { eq: "png" }
        relativePath: { regex: "/feature/" }
        relativeDirectory: { regex: "/content/formations/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`
